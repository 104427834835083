export const Mode = {
  none: 'none',
  addNode: 'addNode',
  edit: 'edit',
  addEdge: 'addEdge',
  dragNode: 'dragNode',
  editMultiNode: 'editMultiNode',
  addLongTouchEdge: 'addLongTouchEdge',
}

export const Button = {
  nodeButton: 'nodeButton',
  edgeButton: 'edgeButton',
  editButton: 'editButton',
}
