import { getCookieValue, ajax } from './helpers'

class Modifiable {
  static createNode = 1
  static editNode = 2

  constructor({ config, network }) {
    this.data = undefined
    this.resolvePromise = undefined
    this.network = network
    this.config = config
  }

  openDialog = () => {
    // Overwrite me
  }

  getDialogInput = () => {
    // Overwrite me
  }

  closeDialog = ids => {
    // slightly dirty distinction: only concepts have a shape. If anyone feels like adding a type to the Modifiable, go ahead.
    this.toggleLock(this.shape ? 'concepts' : 'links', ids, '')
    this.resolvePromise(false)
  }

  focus = id => {
    setTimeout(function () {
      $(id).get(0).focus()
    }, 100)
  }

  setDialogPosition = () => {
    $('#edit-dialog').removeClass('d-none')
    const formWidth = $('#edit-dialog').width()
    const formHeight = $('#edit-dialog').height()

    // mobile fix: detecting the screen size of the virtual keyboard does not
    // seem to be possible, so we simply place the dialog at the top of the
    // screen on smaller screens to ensure it is not obscured by the virtual keyboard.
    if (window.innerWidth < 500) {
      $('#edit-dialog').css({ left: window.innerWidth / 2 - formWidth / 2 + 'px', top: '100px' })
      return
    }
    const xPos =
      $('#map-canvas').offset().left + this.network.canvasToDOM({ x: this.x, y: this.y }).x
    const yPos =
      $('#map-canvas').offset().top +
      this.network.canvasToDOM({ x: this.x, y: this.y }).y -
      formHeight * 0.7
    let leftPos = xPos - formWidth / 2 // Form is in bounds
    let topPos = yPos - formHeight / 2 // Form is in bounds

    // Form is out of bounds right side
    if (formWidth / 2 > window.innerWidth - xPos) {
      leftPos = window.innerWidth - formWidth - 20
    }
    // Form is out of bounds left side
    else if (xPos - formWidth / 2 < 0) {
      leftPos = 20
    }
    //Form is out of bounds bottom
    if (formHeight > window.innerHeight - yPos) {
      topPos = window.innerHeight - formHeight - 20
    }
    //Form is out of bounds top
    else if (yPos - formHeight / 2 < 0) {
      topPos = 20
    }

    $('#edit-dialog').css({ left: leftPos + 'px', top: topPos + 'px' })
  }

  toggleLock = async (type, ids, lock) => {
    if (!this.config.enableCoworking) {
      return
    }
    if (!ids[0]) {
      return
    }
    const lockedBy = lock ? getCookieValue('student') : ''
    // console.log('toggleLock', type, ids, lockedBy)

    const data = {
      task: lock ? 'lock' : 'unlock',
      [type + '_attributes']: ids.map(id => ({
        id: id,
        lock: lockedBy,
      })),
    }
    const res = await ajax({ url: this.config.conceptMapsPath.slice(0, -5), method: 'PUT', data })
    const body = await res.json()
    this.config.handleUpdate(body)
  }
}

export default Modifiable
