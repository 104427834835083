import consumer from './consumer'
import { getCookieValue, getLanguage } from '../js/helpers'

// TODO really needed?
// If the user has other Subscriptions, make sure to remove them: Might be useful in the future
consumer.subscriptions.subscriptions.forEach(subscription => {
  consumer.subscriptions.remove(subscription)
})

// Create the TestChannel subscription, called from initEditor in entrypoints/application.js
export const initSubscription = (handleUpdate, handleCloseDialog) => {
  const codeExtractor = /maps\/(.+?)\/edit/
  const color = getCookieValue('student')
  $('#recent_changes').append(
    `<div>Änderungen werden pro Nutzer in einer anderen Farbe dargestellt. Sie verwenden <span style='color: #${color};'>diese Farbe</span>.</div><hr>`
  )

  const updateLog = data => {
    let text = ''
    switch (data.task + '_' + data.type) {
      case 'create_concepts':
        text = `Konzept hinzugefügt: ${data.concepts.map(concept => concept.label).join(', ')}`
        break
      case 'update_concepts':
        text = `Konzept(e) geändert: ${data.concepts.map(concept => concept.label).join(', ')}`
        break
      case 'destroy_concepts':
        text = `Konzept(e) gelöscht: ${data.concepts.map(concept => concept.label).join(', ')}`
        break
      case 'create_link':
        text = `Link hinzugefügt: ${data.link.label}`
        break
      case 'update_link':
        text = `Link geändert: ${data.link.label}`
        break
      case 'destroy_link':
        text = `Link gelöscht: ${data.concepts.map(concept => concept.label).join(', ')}`
        break
    }
    if (text !== '') {
      $('#recent_changes').append(`<div style='color: #${data.user};'>${text}</div>`)
    }
  }

  consumer.notifications = consumer.subscriptions.create(
    { channel: 'CoworkingChannel' },
    {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Connected!')
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log('Terminated')
      },

      drag(data, concepts) {
        if (!data.student || data.student === getCookieValue('student')) {
          const d = {
            task: 'drag',
            type: 'concepts',
            user: getCookieValue('student'),
            map_code: window.location.pathname.match(codeExtractor)[1],
            concepts: concepts,
          }

          this.perform('drag', { ...d })
        } else {
          handleUpdate(data)
        }
      },

      ping() {
        return this.perform('ping', {
          message: { event: 'ping', student: getCookieValue('student') },
        })
      },

      // Called when there's incoming data on the websocket for this channel
      received(data) {
        // function get the browser language of the user
        let language = getLanguage()

        const code = window.location?.pathname?.match(codeExtractor)[1]

        let currentUser = getCookieValue('student')

        if (!code || code.length !== 6) {
          console.error('Unknown map code')
          return
        }

        // Only if the map of the current user is the same as the map of the sender, we should update the canvas
        if (data.map_code !== code) {
          return
        }

        // if the websocket sends an update from the current user, only close the editor popup
        if (data.user + '' === currentUser + '' && data.subtask === 'close-editor') {
          handleCloseDialog()
        }

        // Check who did an update
        if (data.user + '' === currentUser + '') {
          updateLog(data)
        } else {
          // An other user did the change
          switch (data.task) {
            case 'user_left':
            case 'user_disconnected':
              //console.log('user left', data)
              $('#recent_changes').append(`<div style='color: #${data.user};'>User left</div>`)
              break

            case 'user_joined':
              // add the user to the active users
              //console.log('user joined', data)
              $('#recent_changes').append(`<div style='color: #${data.user};'>User joined</div>`)
              break
            case 'drag':
              handleUpdate(data)
              break
            case 'create':
            case 'update':
            case 'destroy':
              handleUpdate(data)
              updateLog(data)
            default:
              break
          }
        }
      },
    }
  )

  setInterval(function () {
    consumer.notifications.ping()
  }, 30000)

  return consumer
}
