import { getContrastColor } from './helpers'
export const visOptions = (isDarkMode = false) => ({
  autoResize: true,
  height: '100%',
  width: '100%',
  physics: {
    enabled: false,
  },

  interaction: {
    hover: true,
    navigationButtons: true,
    selectConnectedEdges: false,
    hoverConnectedEdges: false,
    multiselect: true,
  },
  manipulation: {
    enabled: false,
    initiallyActive: false,
    addNode: true,
    addEdge: true,
    editEdge: true,
    deleteNode: true,
    deleteEdge: true,
  },

  nodes: {
    borderWidthSelected: 1,
    font: {
      multi: 'md',
    },
    borderWidth: 1,
    borderWidthSelected: 1,
    labelHighlightBold: false,
    widthConstraint: {
      maximum: 120,
    },
  },
  edges: {
    labelHighlightBold: false,
    arrows: 'to',
    arrowStrikethrough: false,
    smooth: { type: 'continuous' },
    color: {
      color: getContrastColor(isDarkMode),
      hover: getContrastColor(isDarkMode),
      highlight: getContrastColor(isDarkMode),
    },
    font: {
      color: getContrastColor(isDarkMode),
      multi: 'md',
      strokeWidth: 0,
    },
    hoverWidth: 0.5,
  },
})
