import './add_jquery'
import * as bootstrap from 'bootstrap'
import '@hotwired/turbo-rails'

import ConceptMap from '../js/ConceptMap'
import BackendViewer from '../js/BackendViewer'
import StudentLog from '../js/StudentLog'
import '../channels/coworking_channel'
import * as XLSX from 'xlsx'

import 'bootstrap-icons/font/bootstrap-icons.css'
import '../scss/application.scss'
import { visOptions } from '../js/visOptions'
import { Network } from 'vis-network'

window.bootstrap = bootstrap

const rV = () => Math.floor(Math.random() * 20 - 10)

window.initIntroMap = () => {
  const nodes = [
    { id: 1, label: 'Concept Maps', x: rV(), y: rV(), color: 'red', shape: 'circle' },
    { id: 2, label: 'Organized Knowledge', x: rV(), y: 2 },
    { id: 3, label: '"Focus Question(s)"', x: 12, y: rV() },
    { id: 4, label: 'Context Dependent', x: rV(), y: rV() },
    { id: 5, label: 'Social', x: rV(), y: rV() },
    { id: 6, label: 'Personal', x: rV(), y: rV() },
    { id: 7, label: 'Effective Teaching', x: rV(), y: rV() },
    { id: 8, label: 'Effective Learning', x: rV(), y: rV() },
    { id: 9, label: 'Concepts', x: rV(), y: rV() },
    { id: 10, label: 'Propositions', x: rV(), y: rV() },
    { id: 11, label: 'Linking Words', x: rV(), y: rV() },
    { id: 0, label: 'Hierarchically structured', x: 0 + rV(), y: rV() },
    { id: 12, label: 'Associated Feelings or Affect', x: rV(), y: rV() },
    { id: 13, label: 'Units of Meaning', x: rV(), y: rV() },
    { id: 14, label: 'Cognitive Structures', x: rV(), y: rV() },
  ]

  const edges = [
    { id: 1, from: 1, to: 2, label: 'represent' },
    { id: 2, from: 1, to: 3, label: 'help to answer' },
    { id: 3, from: 2, to: 3, label: 'needed to answer' },
    { id: 4, from: 2, to: 4, label: 'is' },
    { id: 5, from: 3, to: 4, label: 'are' },
    { id: 6, from: 4, to: 5, label: 'e. g.' },
    { id: 7, from: 4, to: 6, label: 'e. g.' },
    { id: 8, from: 2, to: 7, label: 'necessary for' },
    { id: 9, from: 2, to: 8, label: 'necessary for' },
    { id: 10, from: 2, to: 9, label: 'is comprised of' },
    { id: 11, from: 2, to: 10, label: 'is comprised of' },
    { id: 12, from: 9, to: 11, label: 'connected using' },
    { id: 13, from: 11, to: 10, label: 'used to form' },
    { id: 14, from: 9, to: 0, label: 'are' },
    { id: 15, from: 10, to: 0, label: 'are' },
    { id: 16, from: 2, to: 12, label: 'includes' },
    { id: 17, from: 12, to: 9, label: 'add to' },
    { id: 18, from: 10, to: 13, label: 'are' },
    { id: 19, from: 13, to: 14, label: 'constructed in' },
    { id: 20, from: 0, to: 14, label: 'in' },
    //   { id: , from: , to: , label: '' },
  ]
  const container = $('#fancy_intro')[0]
  const options = visOptions(false)
  options.physics = {
    enabled: true,
    stabilization: { enabled: false },
    solver: 'hierarchicalRepulsion',
    hierarchicalRepulsion: { nodeDistance: 200, springConstant: 0.001 },
    maxVelocity: 5,
    minVelocity: 0.01,
  }
  options.interaction.navigationButtons = false

  const network = new Network(
    container,
    {
      nodes,
      edges,
    },
    options
  )
}

/**********************************
 * intantiates the editor object
 *********************************/
window.initEditor = ({
  edgeData,
  nodeData,
  conceptsPath,
  conceptMapsPath,
  linksPath,
  enableCoworking,
  dialogTexts,
}) => {
  const cm = new ConceptMap({
    edgeData,
    nodeData,
    conceptsPath,
    conceptMapsPath,
    linksPath,
    dialogTexts,
    enableCoworking,
  })

  // expose handlers needed for DOM events
  window.network = cm.network
  window.showForm = cm.showForm
  window.hideForm = cm.hideForm
  window.validateForm = cm.validateForm
  window.searchConcept = cm.searchConcept
  window.sendMail = cm.sendMail
  window.destroy = cm.destroy
  window.submitChanges = event => {
    event.preventDefault()
    event.stopPropagation()
    cm.onSubmit()
    return false
  }
  window.toast = cm.toast
  window.edges = cm.edges
  window.nodes = cm.nodes
  window.canvasX = cm.canvasX
  window.canvasY = cm.canvasY
  window.changeColor = cm.changeColor
  window.changeShape = cm.changeShape
  window.changeEdgeShape = cm.changeEdgeShape
  window.mode = cm.mode
  window.buttonMode = cm.buttonMode
  window.activeButton = cm.activeButton
  window.createEdge = cm.createEdge

  window.setNodeData = cm.setNodeData
  window.setEdgeData = cm.setEdgeData

  //DH Make the mode available
  window.setMode = cm.setMode
}

/**********************************
 * instantiates Objects needed to display concept maps and timelines in admin area
 *********************************/
window.initBackend = ({
  edgeData,
  nodeData,
  id,
  fetchUrl,
  items,
  firstTimestamp,
  lastTimestamp,
  maxVersion,
}) => {
  window.viewers = window.viewers || {}

  // create an instance for the passed concept map id if none is present
  if (!window.viewers[id]) {
    window.viewers[id] = new BackendViewer({ id })
  }

  // function is called from the preview partial (either from the index/list or the show/detail page)
  // this will init the data for the map itself.
  if (edgeData && nodeData) {
    window.viewers[id].setMapData({ id, edgeData, nodeData })
  }

  // function is called from the timeline partial. This will init the timeline data.
  if (fetchUrl) {
    window.viewers[id].setTimelineData({
      id,
      fetchUrl,
      items,
      firstTimestamp,
      lastTimestamp,
      maxVersion,
    })
  }

  return window.viewers[id]
}

/**********************************
 * instantiates student log display handlers
 *********************************/
new StudentLog()

/**********************************
 * function for xlsx export
 *********************************/
window.createXlsxExport = ({ nodes, edges, code }) => {
  // create data
  const connectedNodes = edges.map(edge => {
    const startNode = nodes.find(node => node.id === edge.from)
    const endNode = nodes.find(node => node.id === edge.to)
    return { start: startNode.label, edge: edge.label, end: endNode.label }
  })

  const orphans = nodes
    .filter(node => !edges.find(edge => edge.from === node.id || edge.to === node.id))
    .map(orphan => ({ start: orphan.label, edge: '', end: '' }))

  const rows = [...connectedNodes, ...orphans]
  const headers = ['Startknoten', 'Beschriftung Pfeil', 'Endknoten']

  // create all excel related things
  const worksheet = XLSX.utils.json_to_sheet(rows)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, `Concept Map ${code}`)

  XLSX.utils.sheet_add_aoa(worksheet, [headers], {
    origin: 'A1',
  })

  // calculate and set approximate column widths
  const mwStartNode = rows.reduce((acc, row) => Math.max(row.start.length, acc), headers[0].length)
  const mwEdge = rows.reduce((acc, row) => Math.max(row.edge.length, acc), headers[1].length)
  const mwEndNode = rows.reduce((acc, row) => Math.max(row.end.length, acc), headers[2].length)

  worksheet['!cols'] = [{ wch: mwStartNode }, { wch: mwEdge }, { wch: mwEndNode }]

  // and start download
  XLSX.writeFile(workbook, `concept_map_${code}.xlsx`)
}
