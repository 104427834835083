import Modifiable from './Modifiable'
import {
  ajax,
  setShapeInDialog,
  setColorInDialog,
  getSquareCoordinates,
  getDefaultColor,
} from './helpers'

class Concepts extends Modifiable {
  constructor({ allConcepts, conceptIds, network, config, handleUpdate, handleCloseDialog }) {
    super({ config, network, handleCloseDialog, handleUpdate })

    const { startX, startY, endX, endY } = getSquareCoordinates(conceptIds, allConcepts)
    this.conceptIds = conceptIds
    const { y } = this.network.canvasToDOM({ x: startX, y: startY })
    const { x } = this.network.canvasToDOM({ x: endX, y: endY })
    this.x = endX
    this.y = startY
    this.color = getDefaultColor()
    this.shape = 'ellipse'
  }

  save = async data => {
    const selectedNodes = this.network.getSelectedNodes()

    if (selectedNodes.length) {
      const data = {
        concepts_attributes: selectedNodes.map(nodeId => {
          return { id: nodeId, shape: $('#shape').val(), color: $('#color').val(), lock: '' }
        }),
      }
      const res = await ajax({ url: this.config.conceptMapsPath.slice(0, -5), method: 'PUT', data })
      const body = await res.json()
      if (body.concepts) {
        this.config.handleUpdate(body)
      }
    }
  }

  edit = async () => {
    await this.toggleLock('concepts', this.conceptIds, true)

    const data = await this.openDialog({
      actionText: this.config.dialogTexts.editNode,
    })

    if (data) {
      const res = await this.save(data)
    }

    this.config.handleCloseDialog(undefined)
  }

  delete = async e => {
    e.preventDefault()
    e.stopPropagation()
    await ajax({
      url: 'destroy_multiple_concepts',
      method: 'DELETE',
      data: { concepts_to_delete: this.conceptIds },
    })

    this.network.selectNodes(this.conceptIds)
    this.network.deleteSelected()

    this.config.handleCloseDialog()
  }

  openDialog = ({ actionText }) => {
    this.scale = this.network.getScale()
    this.viewPosition = this.network.getViewPosition()

    $('#context-help-text').html($('#ch_editNodes').html())
    $('#action').html(actionText)

    // show/hide relevant elements in form
    $('#delete-concept').removeClass('d-none')
    $('#entry_concept').addClass('d-none')

    // show/hide relevant form
    $('#link-form').addClass('d-none')
    $('#concept-form').removeClass('d-none')

    $('#shape').attr('value', this.shape)
    $('#color').attr('value', this.color)

    setShapeInDialog(this.shape)
    setColorInDialog(this.color)
    this.setDialogPosition()

    $('#input-dialog-close-button').off()
    $('#input-dialog-close-button').on('click', () => this.closeDialog(this.conceptIds))
    $('#concept-form #save-concept').off()
    $('#concept-form #save-concept').on('click', this.getDialogInput)
    $('#concept-form #delete-concept').off()
    $('#concept-form #delete-concept').on('click', this.delete)

    this.focus('#entry_concept')

    return new Promise(resolve => {
      this.resolvePromise = resolve
    })
  }

  getDialogInput = async e => {
    //console.log('concepts getDialogInput')

    e.preventDefault()
    e.stopPropagation()
    const data = {
      shape: $('#shape').val(),
      color: $('#color').val(),
    }
    this.resolvePromise(data)
  }
}

export default Concepts
