import { createConsumer } from '@rails/actioncable'

let consumer
switch (window.environment) {
  case 'production':
    consumer = createConsumer(`wss://${window.location.host}/cable`)
    break
  case 'staging':
    consumer = createConsumer('wss://ruapehu.informatik.uni-kiel.de/comaped/cable')
    break
  case 'development':
    consumer = createConsumer()
}

export default consumer
