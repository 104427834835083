import Modifiable from './Modifiable'
import { ajax, displayErrorToast, getCookieValue } from './helpers'

class Link extends Modifiable {
  constructor({ link, network, config, handleUpdate, handleCloseDialog, pointer }) {
    super({ config, network, handleCloseDialog, handleUpdate })
    this.id = link.id
    this.label = link.label
    this.from = link.from
    this.to = link.to
    this.x = pointer.canvas.x // needed for dialog position
    this.y = pointer.canvas.y // needed for dialog position
    this.isLocked = link.id && link.lock !== '' && link.lock !== getCookieValue('student')
    //console.log('link constructor', link, this.isLocked, getCookieValue('student'))
  }

  save = async data => {
    const postObj = {}
    postObj['label'] = data.label.replace(/\\/g, ' ')
    if (!this.id) {
      postObj['start_id'] = this.from
      postObj['end_id'] = this.to
    }

    const res = await ajax({
      url: `${this.config.linksPath}${this.id !== undefined ? '/' + this.id : ''}`,
      method: this.id ? 'PUT' : 'POST',
      data: { ...postObj, lock: '' },
    })

    var body = await res.json()

    if (body.link) {
      this.config.handleUpdate(body)
    }
  }

  create = async () => {
    this.edit(true)
  }

  edit = async isNewLink => {
    if (this.isLocked) {
      return
    }

    if (!isNewLink) {
      await this.toggleLock('links', [this.id], true)
    }

    const data = await this.openDialog({
      displayTrash: !isNewLink,
      actionText: isNewLink ? this.config.dialogTexts.addEdge : this.config.dialogTexts.editEdge,
    })

    if (data) {
      const res = await this.save(data)
    }
    this.config.handleCloseDialog()
  }
  delete = async e => {
    e.preventDefault()
    e.stopPropagation()
    await ajax({ url: this.config.linksPath + '/' + this.id, method: 'DELETE' })
    this.network.selectEdges([this.id])
    this.network.deleteSelected()
    this.config.handleCloseDialog()
  }

  openDialog = ({ displayTrash, actionText }) => {
    this.scale = this.network.getScale()
    this.viewPosition = this.network.getViewPosition()

    $('#context-help-text').html($('#ch_newEdge').html())
    $('#action').html(actionText)

    $('#link-form').removeClass('d-none')
    $('#concept-form').addClass('d-none')

    if (displayTrash) {
      $('#delete-link').removeClass('d-none')
    } else {
      $('#delete-link').addClass('d-none')
    }

    $('#entry_link').val(this.label || '')

    this.setDialogPosition()

    $('#input-dialog-close-button').off()
    $('#input-dialog-close-button').on('click', () => this.closeDialog([this.id]))
    $('#link-form #save-link').off()
    $('#link-form #save-link').on('click', this.getDialogInput)
    $('#link-form #delete-link').off()
    $('#link-form #delete-link').on('click', this.delete)

    this.focus('#entry_link')

    return new Promise(resolve => {
      this.resolvePromise = resolve
    })
  }

  getDialogInput = async e => {
    e.preventDefault()
    e.stopPropagation()
    const data = {
      label: $('#entry_link').val(),
    }

    this.resolvePromise(data)
  }
}

export default Link
