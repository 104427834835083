import $ from 'jquery'
import { Network } from 'vis-network'
import { DataSet } from 'vis-data'
import {
  activateButton,
  ajax,
  displayErrorToast,
  getActiveModeFromButton,
  getContrastColor,
  initCoworking,
  initDialogDragging,
  setColorInDialog,
  setDefaultHelpText,
  setHelpText,
  setShapeInDialog,
  getPointerPosition,
} from './helpers'
import Concept from './Concept'
import Link from './Link'
import Concepts from './Concepts'

import { Mode, Button } from './enums'
import { visOptions } from './visOptions'
import CanvasHelper from './CanvasHelper'
import { getCookieValue } from './helpers'

class ConceptMap {
  constructor({
    edgeData,
    nodeData,
    conceptsPath,
    conceptMapsPath,
    linksPath,
    dialogTexts,
    enableCoworking,
  }) {
    this.attachDialogSelectHandlers()

    this.conceptsPath = conceptsPath
    this.conceptMapsPath = conceptMapsPath
    this.linksPath = linksPath
    this.dialogTexts = dialogTexts
    this.enableCoworking = enableCoworking

    // on small devices/phones, the keyboard will resize the canvas when it
    // comes up, and again when it is closed, but somewhat unpredictable.
    // This is to restore the original state.
    this.scale = undefined
    this.viewPosition = undefined

    this.links = new DataSet(edgeData)
    this.concepts = new DataSet(nodeData)

    this.container = $('#map-canvas')[0]

    setHelpText('#ch_editMode')
    this.setMode(Mode.edit)
    this.oldPointerX = 0 // pointerlocation when drag starts
    this.oldPointerY = 0
    this.focusedConceptIndex = undefined // needed to cycle through search results

    this.selectedConceptIds = []

    this.isDarkMode = localStorage.getItem('darkMode') === 'true' ? true : false
    if (this.isDarkMode) {
      $('body').addClass('dark-mode')
      $('#map-canvas').addClass('dark-mode')
    }

    /**********************************
     * SETTINGS for Vis Network library
     ***********************************/

    this.network = new Network(
      this.container,
      {
        nodes: this.concepts,
        edges: this.links,
      },
      visOptions(this.isDarkMode)
    )
    this.canvas = $('.vis-network canvas')[0]

    this.canvasHelper = new CanvasHelper({
      concepts: this.concepts,
      links: this.links,
      network: this.network,
      canvas: this.canvas,
      isDarkMode: this.isDarkMode,
    })
    initDialogDragging()
    this.wsConnection = initCoworking(enableCoworking, this.updateNetwork, this.hideForm)

    this.config = {
      dialogTexts,
      enableCoworking,
      conceptMapsPath,
      conceptsPath,
      linksPath,
      handleUpdate: this.updateNetwork,
      handleCloseDialog: this.hideForm,
      handleHighlight: this.canvasHelper.blink,
    }
    /********************************
     * NODE & EDGE BUTTONS
     ********************************/

    // editButton pressed
    $('#editButton').on('click', () => {
      setHelpText('#ch_editMode')
      this.setMode(Mode.edit)
      this.network.unselectAll()
    })
    // nodeButton pressed
    $('#nodeButton').on('click', () => {
      setHelpText('#ch_addNodeMode')
      this.setMode(Mode.addNode)
      this.network.unselectAll()
    })
    // edgeButton pressed
    $('#edgeButton').on('click', () => {
      setHelpText('#ch_addEdgeMode')
      this.setMode(Mode.addEdge)
      this.network.unselectAll()
    })

    // ESC Pressed
    document.addEventListener('keyup', e => {
      if (e.code == 'Escape') {
        this.hideForm()
        this.closeSearchBox()
        this.setMode(Mode.edit)
        this.network.unselectAll()
      }
    })

    this.network.on('hoverEdge', () => {
      setHelpText('#ch_hoveredge')
      this.network.canvas.body.container.style.cursor = 'pointer'
    })
    this.network.on('hoverNode', params => {
      //console.log('hover node', params, params.node, this.concepts.get(params.node))
      setHelpText('#ch_hovernode')
      this.network.canvas.body.container.style.cursor = 'pointer'
    })

    this.network.on('blurNode', params => {
      //console.log('blur node', params)
      setDefaultHelpText(this.mode)
      this.network.canvas.body.container.style.cursor = 'default'
    })
    this.network.on('blurEdge', () => {
      setDefaultHelpText(this.mode)
      this.network.canvas.body.container.style.cursor = 'default'
    })

    this.network.on('dragStart', this.handleDragStart)
    this.network.on('dragEnd', this.handleDragEnd)
    this.network.on('click', this.handleSingleClick)
    this.network.on('doubleClick', this.createNode)
    this.network.on('hold', this.handleInitLongTouch)
    this.network.on('release', this.handleRelease)
    this.network.on('oncontext', this.disableContextMenu)

    this.network.on('selectNode', this.markSelectedConcepts)
    this.network.on('deselectNode', this.unmarkSelectedConcepts)

    this.network.on('dragging', this.handleDragNodes)
  }

  handleDragNodes = params => {
    if (this.checkIfConceptsLocked(params.nodes)) {
      //console.log('ConceptMap: handleDragNodes: abgeschlossen')

      return
    }
    //console.log('dragging params', params)
    if (params.nodes?.length) {
      const { diffX, diffY } = this.getPointerDiff(params)
      const concepts = this.concepts.get(params.nodes).map(concept => {
        return {
          id: concept.id,
          x: concept.x - diffX,
          y: concept.y - diffY,
          lock: getCookieValue('student'),
        }
      })
      if (this.wsConnection?.notifications) {
        this.wsConnection.notifications.drag(params, concepts)
      }
    }
  }

  markSelectedConcepts = params => {
    //console.log('mark selected nodes', params)
    if (this.checkIfConceptsLocked(params.nodes)) {
      //console.log('ConceptMap: mark selected: abgeschlossen')
      return
    }

    params.nodes.forEach(conceptId => this.canvasHelper.setShadow(conceptId))
    if (params.nodes.length > 1) {
      this.canvasHelper.showMultiEditorButton(params.nodes)
      setHelpText('#ch_selectMultipleNodes', true)
    }
    this.selectedConceptIds = params.nodes
  }

  unmarkSelectedConcepts = params => {
    //console.log('unmark selected nodes', params)
    this.selectedConceptIds = this.selectedConceptIds.filter(
      conceptId => !params.nodes.includes(conceptId)
    )
    this.canvasHelper.hideMultiEditorButton()
    params.previousSelection.nodes.forEach(concept => this.canvasHelper.removeShadow(concept.id))
  }

  // needed to prevent the context menu on mobile devices on long touch events
  disableContextMenu = params => {
    //console.log('disable context menu')

    params.event.preventDefault()
    params.event.stopPropagation()
  }

  handleSingleClick = params => {
    //console.log('singleClick', params.nodes[0])
    if (params.nodes.length === 0 && params.edges.length === 0 && this.mode === Mode.addNode) {
      this.createNode(params)
    } else {
      // necessary here, as the initLongTouch params do not contain a node, if the node was dragged beforehand
      if (params.nodes[0]) {
        this.potentialSourceConcept = params.nodes[0]
      }
      setDefaultHelpText(this.mode, true)
    }
  }
  //TODO Pfeil-Bug
  handleRelease = params => {
    //console.log('release', this.mode, params.nodes[0], this.selectedConceptIds)

    if (
      (this.mode === Mode.addLongTouchEdge || this.mode === Mode.addEdge) &&
      !!this.potentialSourceConcept
    ) {
      //console.log('release add edge', this.potentialSourceConcept, params)

      this.handleFinishLongTouch(params)
    } else if (
      params.nodes.length === 1 &&
      this.mode !== Mode.dragNode &&
      this.mode !== Mode.addEdge
    ) {
      this.editNode(params)
    } else if (params.edges.length === 1) {
      this.editEdge(params)
    }
    // necessary here, as the initLongTouch params do not contain a node if the node was dragged beforehand
    this.potentialSourceConcept = params.nodes[0]
  }

  handleDragStart = params => {
    //console.log('drag start', this.mode, params)
    if (this.checkIfConceptsLocked(params.nodes)) {
      //console.log('drag start cancelled')
      return
    }

    if (this.mode === Mode.addLongTouchEdge) {
      return
    }

    if (this.mode === Mode.addEdge) {
      this.handleInitLongTouch(params)
      return
    }

    if (params.nodes.length > 0) {
      this.setMode(Mode.dragNode)
      this.oldPointerX = params.pointer.canvas.x
      this.oldPointerY = params.pointer.canvas.y
      $('#edit-dialog').addClass('d-none')
      this.canvasHelper.removeAllShadows()
    }
  }

  getPointerDiff = params => {
    return {
      diffX: this.oldPointerX - params.pointer.canvas.x,
      diffY: this.oldPointerY - params.pointer.canvas.y,
    }
  }

  handleDragEnd = async params => {
    if (this.checkIfConceptsLocked(params.nodes)) {
      //console.log('drag end cancelled')
      this.updateNetwork({ task: 'update', concepts: this.concepts.get() })
      return
    }
    //console.log('drag end', this.mode, params.nodes[0], params)
    if (this.mode !== Mode.dragNode) {
      return
    }

    const { diffX, diffY } = this.getPointerDiff(params)

    const selectedNodes = this.network.getSelectedNodes()
    //console.log('drag end selectedNodes', selectedNodes)

    if (selectedNodes.length) {
      const data = {
        concepts_attributes: selectedNodes.map(nodeId => {
          return {
            id: nodeId,
            x: this.concepts.get(nodeId).x - diffX,
            y: this.concepts.get(nodeId).y - diffY,
            lock: '',
          }
        }),
      }
      const res = await ajax({ url: this.conceptMapsPath.slice(0, -5), method: 'PUT', data })
      const body = await res.json()
      //console.log('drag end', res.status, body)

      if (body.concepts) {
        this.updateNetwork(body)
      }
    }
    this.setMode(getActiveModeFromButton())
    if (selectedNodes.length === 1) {
      this.network.unselectAll()
    } else if (selectedNodes.length > 1) {
    }
  }

  handleInitLongTouch = params => {
    if (!this.potentialSourceConcept && !params.nodes[0]) {
      return
    }

    if (
      this.checkIfConceptsLocked([this.potentialSourceConcept]) ||
      this.checkIfConceptsLocked(params.nodes)
    ) {
      return
    }

    if (this.mode !== Mode.addEdge) {
      this.setMode(Mode.addLongTouchEdge)
    }

    if (params.nodes[0]) {
      // this seems to be a bug in vis-network - if a node is dragged before the longtouch, no node is included in the params
      this.potentialSourceConcept = params.nodes[0]
    }

    //console.log('init longtouch', this.mode, params.nodes[0], this.potentialSourceConcept)

    const currentNode = this.concepts.get(this.potentialSourceConcept)

    this.canvasHelper.setShadow(currentNode.id)

    this.network.setOptions({ interaction: { dragNodes: false, dragView: false } })
    const context = this.canvas.getContext('2d')
    this.canvas.networkData = { context, params }
    this.canvas.removeEventListener('mousemove', this.canvasHelper.drawArrow)
    this.canvas.removeEventListener('touchmove', this.canvasHelper.drawArrow)
    this.canvas.addEventListener('mousemove', this.canvasHelper.drawArrow)
    this.canvas.addEventListener('touchmove', this.canvasHelper.drawArrow)

    this.network.unselectAll()
  }

  longTouchCleanup = () => {
    this.canvas.removeEventListener('mousemove', this.canvasHelper.drawArrow)
    this.canvas.removeEventListener('touchmove', this.canvasHelper.drawArrow)
    this.network.setOptions({ interaction: { dragNodes: true, dragView: true } })
    const currentConcept = this.concepts.get(this.potentialSourceConcept)

    this.canvasHelper.stopArrowDrawing(currentConcept.id)
    this.potentialSourceConcept = undefined
  }

  handleFinishLongTouch = params => {
    //console.log('finish', this.mode, params.nodes[0], params, this.potentialSourceConcept)

    if (!this.potentialSourceConcept) {
      this.longTouchCleanup()
      return
    }

    if (
      this.checkIfConceptsLocked([this.potentialSourceConcept]) ||
      this.checkIfConceptsLocked(params.nodes)
    ) {
      this.longTouchCleanup()
      return
    }

    const targetConceptId = this.network.getNodeAt(params.pointer.DOM)

    if (!targetConceptId) {
      this.setMode(Mode.edit)
      this.longTouchCleanup()
      return
    }

    const connectedNodes = this.network.getConnectedNodes(this.potentialSourceConcept, 'to')
    if (connectedNodes.find(edgeId => edgeId === targetConceptId)) {
      displayErrorToast('#doubleEdgeToast')
      this.setMode(Mode.edit)
      this.canvasHelper.removeAllShadows()
    } else {
      this.createEdge({
        from: this.potentialSourceConcept,
        to: targetConceptId,
        params: params,
      })
      this.canvasHelper.stopArrowDrawing(targetConceptId)
    }

    this.longTouchCleanup()
  }

  /*********************************
   * create node
   ********************************/
  createNode = ({ nodes, edges, pointer }) => {
    //console.log('create node', nodes)

    if (nodes.length !== 0 && edges.length !== 0) {
      return
    }
    setHelpText('#ch_editNode')
    const concept = new Concept({
      concept: {
        x: pointer.canvas.x,
        y: pointer.canvas.y,
      },
      network: this.network,
      config: this.config,
    })
    concept.create()
  }

  /*********************************
   * edit node
   ********************************/
  editNode = params => {
    setHelpText('#ch_editNode', true)
    const selectedConcept = this.concepts.get(params.nodes[0])
    const concept = new Concept({
      concept: selectedConcept,
      network: this.network,
      config: this.config,
    })
    concept.edit()
  }

  editMultiNodes = () => {
    setHelpText('#ch_selectMultipleNodes', true)
    const concepts = new Concepts({
      conceptIds: this.selectedConceptIds,
      network: this.network,
      config: this.config,
      allConcepts: this.concepts,
    })
    concepts.edit()
  }

  /*********************************
   * create edge
   ********************************/
  createEdge = ({ from, to, params }) => {
    setHelpText('#ch_editEdge')
    const link = new Link({
      link: { from: from, to: to },
      network: this.network,
      config: this.config,
      pointer: params.pointer,
    })
    link.create()
  }

  /*********************************
   * edit edge
   ********************************/
  editEdge = params => {
    setHelpText('#ch_editEdge', true)
    const selectedLink = this.links.get(params.edges[0])

    const link = new Link({
      link: selectedLink,
      network: this.network,
      config: this.config,
      pointer: params.pointer,
    })
    link.edit()
  }

  /*********************************
   * color- and shapepicker handlers
   *
   * handled here and not in the Modifiable because there is
   * only one edit modal
   ********************************/
  attachDialogSelectHandlers = () => {
    // colorpicker
    $('#currentColor').on('click', () => {
      const isColorPickerOpen = $('#colorSelect').css('display') === 'block'
      $('#colorSelect').css('display', isColorPickerOpen ? 'none' : 'block')
      if (!isColorPickerOpen) $('#shapeSelect').css('display', 'none') // ColorPicker is about to be opened -> close ShapePicker
    })

    for (let i = 0; i < 6; i++) {
      $('#colorSelect-' + i).on('click', () => {
        this.changeColor(i + '')
        $('#colorSelect').css('display', 'none')
      })
    }

    // shapepicker
    $('#currentShape').on('click', () => {
      const isShapePickerOpen = $('#shapeSelect').css('display') === 'block'
      $('#shapeSelect').css('display', isShapePickerOpen ? 'none' : 'block')
      if (!isShapePickerOpen) $('#colorSelect').css('display', 'none') // ShapePicker is about to be opened
    })

    for (let i = 0; i < 3; i++) {
      $('#shapeSelect-' + i).on('click', () => {
        this.changeShape(i + '')
        $('#shapeSelect').css('display', 'none')
      })
    }

    // search button stuff
    $('.search-button-wrapper').on('click', this.closeSearchBox)
    $('#search-input').on('keyup', this.searchConcept)
    $('#search-form').on('submit', this.focusConcept)
    $('#dark-mode-toggle').on('click', this.toggleDarkMode)
    $('#editMultiNodes').on('click', this.editMultiNodes)
  }

  closeSearchBox = () => {
    $('.search-input-slider').toggleClass('is-open')
    $('#search-input').toggleClass('active').focus()
    if (!$('.search-input-slider').hasClass('is-open')) {
      $('#search-input').val('')
      this.focusedConceptIndex = undefined
      setDefaultHelpText()
    } else {
      setHelpText('#ch_search')
    }
  }

  toggleDarkMode = () => {
    this.isDarkMode = !this.isDarkMode
    $('body').toggleClass('dark-mode')
    $('#map-canvas').toggleClass('dark-mode')
    this.canvasHelper.isDarkMode = this.isDarkMode
    this.network.setOptions(visOptions(this.isDarkMode))
    this.network.redraw()
    localStorage.setItem('darkMode', this.isDarkMode)
  }

  changeColor = id => {
    const color = $('#colorSelect-' + id).css('background-color')
    $('#color').attr('value', color)
    setColorInDialog(color, id)
  }

  changeShape(i) {
    const shape = $('#shapeSelect-' + i).attr('value')
    $('#shape').attr('value', shape)
    setShapeInDialog(shape)
  }

  checkIfConceptsLocked = conceptIds => {
    if (!this.enableCoworking) {
      return false
    }
    const isLocked = conceptIds.reduce((acc, id) => {
      const lock = this.concepts.get(id).lock
      //console.log('check lock reduce', id, lock)

      return acc || (lock !== '' && lock !== getCookieValue('student'))
    }, false)

    //console.log('checkIfIsLocked', isLocked)
    return isLocked
  }

  /*******************************************
   * Hides EditForm and sets EditMode active
   *******************************************/
  hideForm = async currentId => {
    $('#edit-dialog').addClass('d-none')
    this.network.unselectAll()
    $('#colorSelect').css('display', 'none') // close colorDropdown
    $('#shapeSelect').css('display', 'none') // close shapeDropdown
    setDefaultHelpText(this.mode, true)

    if (this.mode === Mode.addLongTouchEdge) {
      this.setMode(Mode.edit)
    } else {
      this.setMode(getActiveModeFromButton())
    }

    if (this.scale && this.viewPosition) {
      this.network.moveTo({ scale: this.scale, position: this.viewPosition })
      this.scale = undefined
      this.viewPosition = undefined
    }
    this.network.unselectAll()
    this.canvasHelper.hideMultiEditorButton()
    //console.log('hide form', currentId)

    if (currentId) {
      this.canvasHelper.removeShadow(currentId)
    } else {
      this.canvasHelper.removeAllShadows()
    }
  }

  /*********************************
   * Send Concept Map Code to Mail
   *********************************/
  sendMail = () => {
    $('#emailgroup').removeClass('has-error')
    $('#emailgroup').removeClass('has-success')
    $('#submit').removeClass('btn-danger')
    $('#submit').removeClass('btn-success')
    if ($('#email').is(':valid') && $('#email').val() != '') {
      $.ajax({ url: this.conceptMapsPath + '?email=' + $('#email').val() })
      $('#submit').addClass('btn-success')
      $('#emailgroup').addClass('has-success')
    } else {
      $('#submit').addClass('btn-danger')
      $('#emailgroup').addClass('has-error')
    }
  }

  getConceptsForSearchTerm() {
    const searchTerm = $('#search-input').val()
    if (searchTerm.length < 3) {
      return
    }
    return this.concepts.get({
      filter: node => {
        return node.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      },
    })
  }

  focusConcept = e => {
    e.stopPropagation()
    e.preventDefault()

    const concepts = this.getConceptsForSearchTerm()
    if (!concepts) {
      return
    }
    if (
      this.focusedConceptIndex === undefined ||
      this.focusedConceptIndex === concepts.length - 1
    ) {
      this.focusedConceptIndex = 0
    } else {
      this.focusedConceptIndex++
    }
    //console.log('focusConcept', this.focusedConceptIndex, concepts[this.focusedConceptIndex])

    this.network.moveTo({
      offset: { x: 0, y: -window.innerHeight / 4 }, // place in the upper part of the screen, so a mobile keyboard won't obscure it
      position: {
        x: concepts[this.focusedConceptIndex].x,
        y: concepts[this.focusedConceptIndex].y,
      },
      animation: { duration: 500, easingFunction: 'easeInOutQuad' },
    })
    this.canvasHelper.blink(concepts[this.focusedConceptIndex].id, 2)
  }

  /**********************************************
   * Search for Nodes and move camera to result
   **********************************************/
  searchConcept = e => {
    e.stopPropagation()
    e.preventDefault()
    //console.log('search', e.code, e.keyCode)

    // have to use keyCode here. On Android, any key will have a code of "", with a keyCode of 229, EXCEPT after
    // a submit, where for ENTER, the code is still "", but the keyCode is 13 (same as desktop).
    if (e.keyCode === 13) {
      return
    }
    const concepts = this.getConceptsForSearchTerm()
    if (concepts && concepts.length > 0) {
      if (e.code !== 'Backspace') {
        concepts.forEach(concept => this.canvasHelper.blink(concept.id, 2))
      }

      this.network.fit({
        nodes: concepts.map(c => c.id),
        animation: { duration: 500, easingFunction: 'easeInOutQuad' },
      })
    }
  }

  /******************************************************
   * Highlights the active button (Edit/Add-Node/Add-Edge)
   *******************************************************/
  setMode = mode => {
    this.mode = mode
    switch (mode) {
      case Mode.addEdge:
      case Mode.addLongTouchEdge:
        activateButton(Button.edgeButton)
        break
      case Mode.addNode:
        activateButton(Button.nodeButton)
        break
      case Mode.edit:
        activateButton(Button.editButton)
    }
  }

  updateNetwork = data => {
    //console.log('updateNetwork', data)
    if (data.task === 'update' || data.task === 'drag' || data.task === 'create') {
      if (data.links || data.link) {
        //console.log('update link', data.link, data.links)

        if (this.mode === Mode.addLongTouchEdge && data.user === getCookieValue('student')) {
          this.setMode(Mode.edit)
        }
        let links = data.links || data.link //TODO auf eins einigen
        if (!Array.isArray(links)) {
          links = [links]
        }
        const codedLinks = links.map(link => {
          if (link.lock !== '' && link.lock !== getCookieValue('student')) {
            link.font = link.font || {}
            link.font.color = '#8888888'
          } else {
            link.font = link.font || {}
            link.font.color = getContrastColor(this.isDarkMode)
          }
          return { ...link, to: link.end_id, from: link.start_id }
        })
        this.links.update(codedLinks)
      }
      if (data.concepts) {
        let concepts = data.concepts
        if (!Array.isArray(concepts)) {
          concepts = [data.concepts]
        }
        const codedConcepts = concepts.map(concept => {
          if (concept.lock !== '' && concept.lock !== getCookieValue('student')) {
            concept.color = '#888888'
          }
          return concept
        })

        this.concepts.update(codedConcepts)
        //console.log('update concepts', codedConcepts, this.concepts.get())
      }
    } else if (data.task === 'destroy' && data.type.startsWith('concept')) {
      //console.log('destroy concept', data)

      this.concepts.remove(data.concepts)
    } else if (data.task === 'destroy' && data.type.startsWith('link')) {
      //console.log('destroy link', data)
      this.links.remove(data.link)
    } else {
      console.error('ConceptMap.updateNetwork: unknown response parameter', data)
    }
  }
}

export default ConceptMap
