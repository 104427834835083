import Modifiable from './Modifiable'
import {
  ajax,
  setShapeInDialog,
  setColorInDialog,
  getDefaultColor,
  displayErrorToast,
  getCookieValue,
} from './helpers'

class Concept extends Modifiable {
  constructor({ concept, network, config }) {
    super({ config, network })

    this.id = concept.id
    this.x = concept.x
    this.y = concept.y
    this.label = concept.label
    this.color = concept.color || concept.color?.background || getDefaultColor()
    this.shape = concept.shape
    this.isLocked = concept.id && concept.lock !== '' && concept.lock !== getCookieValue('student')
  }

  save = async data => {
    const postObj = {}
    postObj['x'] = this.x
    postObj['y'] = this.y
    postObj['shape'] = data.shape
    postObj['color'] = data.color
    postObj['label'] = data.label.replace(/\\/g, ' ')

    const res = await ajax({
      url: `${this.config.conceptsPath}${this.id !== undefined ? '/' + this.id : ''}`,
      method: this.id ? 'PUT' : 'POST',
      data: { ...postObj, lock: '' },
    })

    var body = await res.json()

    if (body.concepts) {
      this.config.handleUpdate(body)
    }
  }

  create = async () => {
    this.edit(true)
  }

  edit = async isNewConcept => {
    if (this.isLocked) {
      return
    }
    if (!isNewConcept) {
      await this.toggleLock('concepts', [this.id], true)
    }

    const data = await this.openDialog({
      displayTrash: !isNewConcept,
      actionText: isNewConcept ? this.config.dialogTexts.addNode : this.config.dialogTexts.editNode,
    })

    if (data) {
      const res = await this.save(data)
    }
    this.config.handleCloseDialog(this.id)
  }
  delete = async e => {
    e.preventDefault()
    e.stopPropagation()
    await ajax({ url: this.config.conceptsPath + '/' + this.id, method: 'DELETE' })
    this.network.selectNodes([this.id])
    this.network.deleteSelected()

    this.config.handleCloseDialog()
  }

  conceptNameExists = () => {
    var label = $('#entry_concept').val()

    const concept = Object.values(this.network.body.nodes).find(
      node => node.options.label.toLowerCase() === label.toLowerCase()
    )
    if (!concept || concept.length === 0 || concept.id === this.id) {
      return -1
    } else {
      return concept.id
    }
  }

  openDialog = ({ displayTrash, actionText }) => {
    if (this.isLocked) {
      return
    }

    this.scale = this.network.getScale()
    this.viewPosition = this.network.getViewPosition()

    $('#context-help-text').html($('#ch_newNode').html())
    $('#action').html(actionText)

    $('#entry_concept').removeClass('d-none')
    $('#link-form').addClass('d-none')
    $('#concept-form').removeClass('d-none')

    if (displayTrash) {
      $('#delete-concept').removeClass('d-none')
    } else {
      $('#delete-concept').addClass('d-none')
    }

    $('#entry_concept').val(this.label || '')
    $('#x').attr('value', this.x)
    $('#y').attr('value', this.y)
    $('#shape').attr('value', this.shape)
    $('#color').attr('value', this.color)

    setShapeInDialog(this.shape)
    setColorInDialog(this.color)
    this.setDialogPosition()

    // dialog close handlers
    $('#input-dialog-close-button').off()
    $('#input-dialog-close-button').on('click', () => this.closeDialog([this.id]))
    $('#concept-form #save-concept').off()
    $('#concept-form #save-concept').on('click', this.getDialogInput)
    $('#concept-form #delete-concept').off()
    $('#concept-form #delete-concept').on('click', this.delete)

    this.focus('#entry_concept')

    return new Promise(resolve => {
      this.resolvePromise = resolve
    })
  }

  getDialogInput = async e => {
    e.preventDefault()
    e.stopPropagation()
    const data = {
      x: $('#x').val(),
      y: $('#y').val(),
      label: $('#entry_concept').val(),
      shape: $('#shape').val(),
      color: $('#color').val(),
    }

    const existingNodeId = this.conceptNameExists()
    if (existingNodeId !== -1) {
      this.config.handleHighlight(existingNodeId)
      displayErrorToast('#doubleNodeToast')
      return
    }

    if (!data.label) {
      displayErrorToast('#noLabelToast')
      return
    }

    this.resolvePromise(data)
  }
}

export default Concept
