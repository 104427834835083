import { getPointerPosition, getContrastColor, getSquareCoordinates } from './helpers'

class CanvasHelper {
  constructor({ concepts, links, network, canvas, isDarkMode }) {
    this.concepts = concepts
    this.links = links
    this.network = network
    this.canvas = canvas
    this.isDarkMode = isDarkMode

    this.startConceptId = undefined
    this.blinkingConcepts = {}
  }

  setShadow = conceptId => {
    const concept = this.concepts.get(conceptId)
    concept.shadow = {
      enabled: true,
      color: this.getContrastColor(),
      size: this.isDarkMode ? 20 : 10,
      x: 0,
      y: 0,
    }
    this.concepts.update(concept)
  }

  removeShadow = conceptId => {
    const concept = this.concepts.get(conceptId)
    concept.shadow = {
      enabled: false,
    }
    this.concepts.update(concept)
  }

  removeAllShadows = () => {
    const allConcepts = this.concepts.get()
    const updatedConcepts = allConcepts.map(concept => {
      concept.shadow = {
        enabled: false,
      }
      return concept
    })
    this.concepts.update(updatedConcepts)
  }

  showMultiEditorButton = conceptIds => {
    const { startX, startY, endX, endY } = getSquareCoordinates(conceptIds, this.concepts)

    const { y } = this.network.canvasToDOM({ x: startX, y: startY })
    const { x } = this.network.canvasToDOM({ x: endX, y: endY })

    $('#editMultiNodes')
      .removeClass('d-none')
      .css({ left: x + 'px', top: y + 'px' })
  }

  hideMultiEditorButton = () => {
    $('#editMultiNodes').addClass('d-none').css({ left: '-100px', top: '-100px' })
  }

  drawArrow = event => {
    const params = this.canvas.networkData.params
    const context = this.canvas.networkData.context

    this.network.redraw() // delete previous arrows from canvas
    if (!this.startConceptId && params.nodes[0]) {
      this.startConceptId = params.nodes[0]
    }

    const startX = params.pointer.DOM.x
    const startY = params.pointer.DOM.y
    const { currentX, currentY } = getPointerPosition(event)

    const currentHoveredConceptId = this.network.getNodeAt({ x: currentX, y: currentY })
    if (currentHoveredConceptId && !this.isHoveringOverConcept) {
      this.lastHoveredConceptId = currentHoveredConceptId

      this.setShadow(currentHoveredConceptId)
      this.isHoveringOverConcept = true
    } else if (!currentHoveredConceptId) {
      if (this.isHoveringOverConcept && this.lastHoveredConceptId !== this.startConceptId) {
        this.removeShadow(this.lastHoveredConceptId)
      }
      this.isHoveringOverConcept = false
    }

    const angle = Math.atan2(currentY - startY, currentX - startX)

    context.fillStyle = this.getContrastColor()
    context.strokeStyle = this.getContrastColor()
    context.beginPath()
    context.moveTo(startX, startY)
    context.lineTo(currentX, currentY)
    context.stroke()

    const arrowSize = 10
    const arrowAngle = Math.PI / 6
    context.beginPath()
    context.moveTo(currentX, currentY)
    context.lineTo(
      currentX - arrowSize * Math.cos(angle - arrowAngle),
      currentY - arrowSize * Math.sin(angle - arrowAngle)
    )
    context.lineTo(
      currentX - arrowSize * Math.cos(angle + arrowAngle),
      currentY - arrowSize * Math.sin(angle + arrowAngle)
    )
    context.lineTo(currentX, currentY)
    context.stroke()
    context.fill()
  }

  stopArrowDrawing = conceptId => {
    this.removeShadow(conceptId)
    this.startConceptId = undefined
  }

  getContrastColor = () => getContrastColor(this.isDarkMode)

  highlightConcept = (selectedConcept, borderColor, borderWidth) => {
    if (!borderColor) {
      return
    }
    if (typeof selectedConcept.color === 'string') {
      selectedConcept.color = { background: selectedConcept.color, border: borderColor }
    } else {
      selectedConcept.color.border = borderColor
    }
    selectedConcept.borderWidth = borderWidth
    this.concepts.update(selectedConcept)
  }

  blink = (conceptId, count = 4) => {
    if (this.blinkingConcepts[conceptId]) {
      return
    }

    const selectedConcept = this.concepts.get(conceptId)
    const oldColor = selectedConcept.color.border
      ? selectedConcept.color.border
      : selectedConcept.color
    this.highlightConcept(selectedConcept, '#ff0000', 3)
    this.blinkingConcepts[conceptId] = true

    for (let i = 1; i < count * 2; i++) {
      setTimeout(() => {
        this.highlightConcept(selectedConcept, i % 2 ? oldColor : '#ff0000', i % 2 ? 1 : 3)
      }, i * 300)
    }
    setTimeout(() => {
      this.blinkingConcepts[conceptId] = false
    }, count * 2 * 300)
  }
}

export default CanvasHelper
