import { Mode, Button } from './enums'
import { initSubscription } from '../channels/coworking_channel'

export const getLanguage = () => {
  const language =
    navigator.userLanguage ||
    (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage
  return language.substr(0, 2)
}

export const ajax = async ({
  url,
  method = 'GET',
  data,
  contentType = 'application/json',
  accept = 'application/json',
}) => {
  return await fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': getCSRFToken(),
      'Content-Type': contentType,
      Accept: accept,
    },
    mode: 'same-origin',
    method: method,
    body: contentType === 'application/json' ? JSON.stringify(data) : data,
  })
}

export const getCSRFToken = () => {
  return document.getElementsByName('csrf-token')[0].getAttribute('content')
}

const standardizeColor = color => {
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.fillStyle = color
  return ctx.fillStyle
}

export const getDefaultColor = () => {
  return $('#colorSelect-0').css('background-color')
}

export const setColorInDialog = color => {
  $('#currentColor').css('background-color', color)
  $('#color').attr('value', standardizeColor(color))

  const selectedColorIcon = $('.colorSelectIcon').filter(function () {
    return $(this).css('background-color') === color
  })

  $('.colorSelectIcon').each(function () {
    $(this).html('<span></span>')
  })

  selectedColorIcon.html("<span class='bi-check-lg'></span>")
  $('#entry_concept').focus()
}

export const setShapeInDialog = shape => {
  switch (shape) {
    case 'circle':
      $('#currentShape').addClass('currently-circle').removeClass('currently-box currently-ellipse')
      break
    case 'box':
      $('#currentShape').addClass('currently-box').removeClass('currently-circle currently-ellipse')
      break
    case 'ellipse':
      $('#currentShape').addClass('currently-ellipse').removeClass('currently-box currently-circle')
      break
  }
}

let currentlyActiveHelp = undefined
const isHelpTextPinned = () => {
  return (
    currentlyActiveHelp === '#ch_editNode' ||
    currentlyActiveHelp === '#ch_editNodes' ||
    currentlyActiveHelp === '#ch_selectMultipleNodes' ||
    currentlyActiveHelp === '#ch_editEdge'
  )
}

export const setHelpText = (textId, force) => {
  // don't change the help text if the editor box is open
  if (!force && isHelpTextPinned()) {
    return
  }

  currentlyActiveHelp = textId
  $('#context-help > p').addClass('d-none')
  $(`#context-help ${textId}`).removeClass('d-none')
}

export const setDefaultHelpText = (mode, force) => {
  if (!force && isHelpTextPinned()) {
    return
  }

  switch (mode) {
    case Mode.addEdge:
    case Mode.addLongTouchEdge:
      setHelpText('#ch_addEdgeMode', true)
      break
    case Mode.addNode:
      setHelpText('#ch_addNodeMode', true)
      break
    case Mode.edit:
      setHelpText('#ch_editMode', true)
      break
    default:
      setHelpText('#ch_editMode', true)
  }
}

export const getActiveModeFromButton = () => {
  const activeButton = $('.active-button').first()
  switch (activeButton.attr('id')) {
    case 'editButton':
      return Mode.edit
    case 'nodeButton':
      return Mode.addNode
    case 'edgeButton':
      return Mode.addEdge
    default:
      return Mode.edit
  }
}

export const activateButton = type => {
  $('#editButton').removeClass('active-button')
  $('#nodeButton').removeClass('active-button')
  $('#edgeButton').removeClass('active-button')
  if (type == Button.editButton) {
    $('#editButton').addClass('active-button')
  } else if (type == Button.nodeButton) {
    $('#nodeButton').addClass('active-button')
  } else if (type == Button.edgeButton) {
    $('#edgeButton').addClass('active-button')
  }
}

export const displayErrorToast = errorId => {
  const currentHelpText = $('#context-help > p:not(.d-none)')
  const errorMessage = $(errorId)
  const errorToast = $('#context-help')

  !!currentHelpText.length && errorToast.fadeOut(500).removeClass('d-flex')
  errorMessage.fadeIn(500)
  setTimeout(function () {
    errorMessage.fadeOut(500)
    !!currentHelpText.length && $('#context-help').addClass('d-flex').fadeIn(500)
  }, 4000)
}

export const getPointerPosition = e => {
  let currentX
  let currentY
  const offset = $('#map-canvas').offset()
  switch (e.type) {
    case 'mousemove':
      currentX = e.clientX - offset.left
      currentY = e.clientY - offset.top
      break
    case 'touchmove':
      currentX = (e.touches[0]?.clientX || e.targetTouches[0]?.clientX) - offset.left
      currentY = (e.touches[0]?.clientY || e.targetTouches[0]?.clientY) - offset.top
      break
  }
  return { currentX, currentY }
}

export const getSquareCoordinates = (conceptIds, allConcepts) => {
  const startX = conceptIds.reduce((acc, id) => {
    const concept = allConcepts.get(id)
    return Math.min(acc, concept.x)
  }, Infinity)

  const startY = conceptIds.reduce((acc, id) => {
    const concept = allConcepts.get(id)
    return Math.min(acc, concept.y)
  }, Infinity)

  const endX = conceptIds.reduce((acc, id) => {
    const concept = allConcepts.get(id)
    return Math.max(acc, concept.x)
  }, -Infinity)

  const endY = conceptIds.reduce((acc, id) => {
    const concept = allConcepts.get(id)
    return Math.max(acc, concept.y)
  }, -Infinity)

  return { startX, startY, endX, endY }
}

export const initCoworking = (enableCoworking, handleUpdate, handleCloseDialog) => {
  // init websocket connection only if enabled in the project settings
  let isSubscriptionInitialized = false
  const isCoworkingEnabled = enableCoworking && enableCoworking !== 'false'
  if (!isSubscriptionInitialized && isCoworkingEnabled) {
    const wsConnection = initSubscription(handleUpdate, handleCloseDialog)
    isSubscriptionInitialized = true
    return wsConnection
  }
}

export const initDialogDragging = () => {
  const editDialog = document.getElementById('edit-dialog')
  let startX = 0
  let startY = 0
  let endX = 0
  let endY = 0
  document.getElementById(editDialog.id + '-header').onmousedown = dragMouseDown

  function dragMouseDown(e) {
    e.stopPropagation()
    e.preventDefault()
    endX = e.clientX
    endY = e.clientY
    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }
  function elementDrag(e) {
    e.stopPropagation()
    e.preventDefault()
    startX = endX - e.clientX
    startY = endY - e.clientY
    endX = e.clientX
    endY = e.clientY
    editDialog.style.top = editDialog.offsetTop - startY + 'px'
    editDialog.style.left = editDialog.offsetLeft - startX + 'px'
  }
  function closeDragElement() {
    document.onmouseup = null
    document.onmousemove = null
  }
}
export const getContrastColor = isDarkMode => {
  if (isDarkMode) {
    return '#ffffff'
  } else {
    return '#333333'
  }
}

export const getCookieValue = cookieName => {
  const cookies = document.cookie.split('; ')
  const cookie = cookies.find(cookie => cookie.startsWith(cookieName + '='))

  if (cookie) {
    return cookie.split('=')[1]
  } else {
    return null
  }
}
